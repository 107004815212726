<template>
  <div class="commission">
    <HomeHeader />
    <div class="img">
      <img src="@/assets/images/pc/Inside/Inside-pc-1.png" alt="" />
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/pc/HomeHeader";
// import PromoBanner from "@/components/pc/PromoBanner"
import elementHeight from "@/mixins/pc/elementHeight";

export default {
  name: "Commission",
  mixins: [elementHeight],
  // data() {
  //   return {
  //     promoBannerSrc: require("@/assets/images/pc/commission/banner.png"),
  //     promoTitleSrc: require("@/assets/images/pc/promo-title.png"),
  //     promoRuleSrc: require("@/assets/images/pc/promo-rule.png"),
  //     promoContentList,
  //     ruleList
  //   }
  // },
  components: {
    HomeHeader,
  },
};
</script>

<style lang="less" scoped>
.commission {
  font-size: 16px;

  img {
    width: 100%;
  }
}
</style>
